:root ._dsp-flex{display:flex;}
:root ._ai-stretch{align-items:stretch;}
:root ._fd-column{flex-direction:column;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-1{flex-shrink:1;}
:root ._fg-1{flex-grow:1;}
:root ._maw-1300px{max-width:1300px;}
:root ._fs-0{flex-shrink:0;}
:root ._ac-center{align-content:center;}
:root ._ai-center{align-items:center;}
:root ._fd-row{flex-direction:row;}
:root ._jc-flex-start{justify-content:flex-start;}
:root ._pt-24px{padding-top:24px;}
:root ._pb-24px{padding-bottom:24px;}
:root ._mb-t-space-sm{margin-bottom:var(--t-space-sm);}