:root ._dsp-flex{display:flex;}
:root ._ai-stretch{align-items:stretch;}
:root ._fd-column{flex-direction:column;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._pos-absolute{position:absolute;}
:root ._t-0px{top:0px;}
:root ._l-0px{left:0px;}
:root ._w-10037{width:100%;}
:root ._b--1px{bottom:-1px;}
:root ._zi-0{z-index:0;}
:root ._pos-relative{position:relative;}
:root ._ac-center{align-content:center;}
:root ._ai-center{align-items:center;}
:root ._fd-row{flex-direction:row;}
:root ._jc-space-betwe3241{justify-content:space-between;}
:root ._pr-t-space-bas101{padding-right:var(--t-space-base);}
:root ._pl-t-space-bas101{padding-left:var(--t-space-base);}
:root ._pt-24px{padding-top:24px;}
:root ._pb-24px{padding-bottom:24px;}
:root ._maw-10037{max-width:100%;}
:root ._mr-auto{margin-right:auto;}
:root ._ml-auto{margin-left:auto;}
:root ._jc-center{justify-content:center;}
:root ._gap-t-space-sm{gap:var(--t-space-sm);}
:root ._dsp-inline{display:inline;}
:root ._ww-break-word{word-wrap:break-word;}
:root ._ff-f-family{font-family:var(--f-family);}
:root ._col-colorTitle{color:var(--colorTitle);}
:root ._ussel-auto{user-select:auto;-webkit-user-select:auto;}
:root ._whiteSpace-normal{white-space:normal;}
:root ._mt-0px{margin-top:0px;}
:root ._mr-0px{margin-right:0px;}
:root ._mb-0px{margin-bottom:0px;}
:root ._ml-0px{margin-left:0px;}
:root ._ta-center{text-align:center;}
:root ._fos-f-size-3{font-size:var(--f-size-3);}
:root ._lh-f-lineHeigh112922{line-height:var(--f-lineHeight-3);}
:root ._mih-t-size-11{min-height:var(--t-size-11);}
:root ._fos-f-size-2{font-size:var(--f-size-2);}
:root ._lh-f-lineHeigh112921{line-height:var(--f-lineHeight-2);}
:root ._mih-t-size-7{min-height:var(--t-size-7);}
:root ._mt-t-space-lg{margin-top:var(--t-space-lg);}
:root ._fs-1{flex-shrink:1;}
:root ._pt-t-space-xl{padding-top:var(--t-space-xl);}
:root ._pb-t-space-xl{padding-bottom:var(--t-space-xl);}
:root ._fg-1{flex-grow:1;}
:root ._maw-1300px{max-width:1300px;}
:root ._mt-auto{margin-top:auto;}
:root ._mb-auto{margin-bottom:auto;}