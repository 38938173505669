:root ._dsp-flex{display:flex;}
:root ._ai-stretch{align-items:stretch;}
:root ._fd-column{flex-direction:column;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._bg-backgroundP930930286{background-color:var(--backgroundPrimary);}
:root ._h-300px{height:300px;}
:root ._pr-t-space-bas101{padding-right:var(--t-space-base);}
:root ._pl-t-space-bas101{padding-left:var(--t-space-base);}
:root ._mt-auto{margin-top:auto;}
:root ._pt-24px{padding-top:24px;}
:root ._pb-24px{padding-bottom:24px;}
:root ._ai-center{align-items:center;}
:root ._jc-center{justify-content:center;}
:root ._ai-flex-start{align-items:flex-start;}
:root ._jc-flex-start{justify-content:flex-start;}
:root ._fd-row{flex-direction:row;}
:root ._fw-wrap{flex-wrap:wrap;}
:root ._gap-t-space-bas101{gap:var(--t-space-base);}
:root ._mb-t-space-sm{margin-bottom:var(--t-space-sm);}
:root ._gap-t-space-sm{gap:var(--t-space-sm);}
:root ._mb-t-space-bas101{margin-bottom:var(--t-space-base);}
:root ._mt-t-space-bas101{margin-top:var(--t-space-base);}