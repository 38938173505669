:root ._dsp-flex{display:flex;}
:root ._ai-stretch{align-items:stretch;}
:root ._fd-column{flex-direction:column;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._bg-rgba0000--638295333{background-color:rgba(0,0,0,0.6);}
:root ._btlr-t-radius-6{border-top-left-radius:var(--t-radius-6);}
:root ._bbrr-t-radius-6{border-bottom-right-radius:var(--t-radius-6);}
:root ._t-0px{top:0px;}
:root ._l-0px{left:0px;}
:root ._pt-t-space-xs{padding-top:var(--t-space-xs);}
:root ._pr-t-space-xs{padding-right:var(--t-space-xs);}
:root ._pb-t-space-xs{padding-bottom:var(--t-space-xs);}
:root ._pl-t-space-xs{padding-left:var(--t-space-xs);}
:root ._pos-absolute{position:absolute;}
:root ._maw-10037{max-width:100%;}
:root ._btrr-t-radius-3{border-top-right-radius:var(--t-radius-3);}
:root ._btrr-0px{border-top-right-radius:0px;}
:root ._dsp-inline{display:inline;}
:root ._ww-break-word{word-wrap:break-word;}
:root ._whiteSpace-pre-wrap{white-space:pre-wrap;}
:root ._mt-0px{margin-top:0px;}
:root ._mr-0px{margin-right:0px;}
:root ._mb-0px{margin-bottom:0px;}
:root ._ml-0px{margin-left:0px;}
:root ._ff-f-family{font-family:var(--f-family);}
:root ._fow-400{font-weight:400;}
:root ._ls-f-letterSpa1360334201{letter-spacing:var(--f-letterSpacing-5);}
:root ._fos-f-size-5{font-size:var(--f-size-5);}
:root ._lh-f-lineHeigh112924{line-height:var(--f-lineHeight-5);}
:root ._col-colorInvers101{color:var(--colorInverse);}
:root ._tt-uppercase{text-transform:uppercase;}
:root ._textWrap-nowrap{text-wrap:nowrap;}
:root ._textOverflow-ellipsis{text-overflow:ellipsis;}
:root ._fos-f-size-3{font-size:var(--f-size-3);}
:root ._fos-f-size-4{font-size:var(--f-size-4);}
:root ._pos-relative{position:relative;}
:root ._ox-hidden{overflow-x:hidden;}
:root ._oy-hidden{overflow-y:hidden;}
:root ._pt-t-space-sm{padding-top:var(--t-space-sm);}
:root ._pr-t-space-sm{padding-right:var(--t-space-sm);}
:root ._pb-t-space-sm{padding-bottom:var(--t-space-sm);}
:root ._pl-t-space-sm{padding-left:var(--t-space-sm);}
:root ._transition-background-2006335604{transition:background-color 0.4s ease;}
@media (hover) {:root  ._cur-0hover-pointer:hover{cursor:pointer !important;}}
@media (hover) {:root  ._bg-0hover-backgroundC494184292:hover{background-color:var(--backgroundCardsHover) !important;}}
:root ._btlr-t-radius-0{border-top-left-radius:var(--t-radius-0);}
:root ._btrr-t-radius-0{border-top-right-radius:var(--t-radius-0);}
:root ._bbrr-t-radius-0{border-bottom-right-radius:var(--t-radius-0);}
:root ._bblr-t-radius-0{border-bottom-left-radius:var(--t-radius-0);}
:root ._fd-row{flex-direction:row;}
:root ._btlr-t-radius-8{border-top-left-radius:var(--t-radius-8);}
:root ._btrr-t-radius-8{border-top-right-radius:var(--t-radius-8);}
:root ._bbrr-t-radius-8{border-bottom-right-radius:var(--t-radius-8);}
:root ._bblr-t-radius-8{border-bottom-left-radius:var(--t-radius-8);}
:root ._fb-10037{flex-basis:100%;}
@media (max-width: 800px){:root:root:root:root ._pr-_sm_t-space-0{padding-right:var(--t-space-0);}}
@media (max-width: 800px){:root:root:root:root ._pl-_sm_t-space-0{padding-left:var(--t-space-0);}}
:root ._w-10037{width:100%;}
:root ._w-260px{width:260px;}
:root ._bg-backgroundW214952015{background-color:var(--backgroundWarningLight);}
:root ._bg-background{background-color:var(--background);}
:root ._h-110px{height:110px;}
:root ._w-110px{width:110px;}
:root ._h-138px{height:138px;}
:root ._w-234px{width:234px;}
:root ._fs-1{flex-shrink:1;}
:root ._mt-t-space-sm{margin-top:var(--t-space-sm);}
:root ._als-center{align-self:center;}
:root ._als-flex-start{align-self:flex-start;}
@media (max-width: 800px){:root:root:root:root ._ml-_sm_t-space-bas101{margin-left:var(--t-space-base);}}
:root ._dsp--webkit-box{display:-webkit-box;}
:root ._fow-700{font-weight:700;}
:root ._col-color{color:var(--color);}
:root ._ussel-auto{user-select:auto;-webkit-user-select:auto;}
:root ._whiteSpace-normal{white-space:normal;}
:root ._WebkitLineClamp-2{-webkit-line-clamp:2;}
:root ._WebkitBoxOrient-vertical{-webkit-box-orient:vertical;}
:root ._mt-t-space-xxs{margin-top:var(--t-space-xxs);}
:root ._fow-500{font-weight:500;}
:root ._lh-f-lineHeigh112922{line-height:var(--f-lineHeight-3);}
:root ._btc-borderColor{border-top-color:var(--borderColor);}
:root ._brc-borderColor{border-right-color:var(--borderColor);}
:root ._bbc-borderColor{border-bottom-color:var(--borderColor);}
:root ._blc-borderColor{border-left-color:var(--borderColor);}
:root ._btw-0px{border-top-width:0px;}
:root ._brw-0px{border-right-width:0px;}
:root ._bbw-1px{border-bottom-width:1px;}
:root ._blw-0px{border-left-width:0px;}
:root ._fg-1{flex-grow:1;}
:root ._h-0px{height:0px;}
:root ._mah-0px{max-height:0px;}
:root ._bbs-solid{border-bottom-style:solid;}
:root ._bts-solid{border-top-style:solid;}
:root ._bls-solid{border-left-style:solid;}
:root ._brs-solid{border-right-style:solid;}
:root ._transform-translateY-1736186894{transform:translateY(-0.5px);}
:root ._pb-t-space-xl{padding-bottom:var(--t-space-xl);}
:root ._ai-flex-start{align-items:flex-start;}
:root ._ai-center{align-items:center;}